//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 4,
    $prefab: 8
  );
}

// Choose your card style for home feature cards
.homeFeatures .homeFeature {
  @include card (
    $card-text-align: center,
    $card-border: 0,
    $card-details-background-colour: rgba( $brand-secondary, .9 ),
    $card-summary-colour: #fff,
    $card-heading-colour: #fff,
    $card-hover-details-background-colour: rgba( $brand-secondary, .9 ),
    $card-hover-summary-colour: #fff // Hmm, not working on this kind of card?
  );
  @include card-text-overlay (

  );
}

//
// Home feeds
//

// Choose your overall home feeds layout
@include home-feeds-prefab (
  $number-of-feeds: 2,
  $prefab: 1
);

// Specify a card layout for each individual feed
.feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1,
  );
}

// Choose your card style for home feed cards
.feedItem {
  @include card (
    $card-border: none,
    $card-details-background-colour: $grey-lightest,
    $card-hover-details-background-colour: $brand-tertiary,
    $card-hover-heading-colour: #fff
  );
  @include card-basic;
}


//
// Listed Posts
//

// Choose your card style for listed post cards
.listedPost {
  @include card (
  );
  @include card-basic(
    $prefab: 2
  );
}
