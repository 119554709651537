
.pageHeader .headerContent > *:not(.mainLogo) {
  margin-top: 1.4rem;
  margin-bottom: .5rem;
}

// listed items bg color
.listedPost [class*="image-link"] {
  margin-bottom: 0;
}
.listedPost [class*="DetailsWrapper"]:not(.publishDetailsWrapper),
.listedPost [class^="listed"][class$="Text"] {
  padding: 1rem;
  background-color: rgba(0,0,0,0.05);
}

// Map page listed items - needs Fresco attention
.pageHasGoogleMapAndListing .listedPost {
  width: 100%;
}

@include max-width(lg) {
  .pageHeader .headerContent {
    margin-bottom: 1rem;
  }

  // .pageHeader:not(.sticky) .menuMainAlt {
  //   transform: none;
  //   bottom: 26px;
  // }
}

// @include max-width(md) {
//   .headerContent .mainCallToAction {
//     padding-right: 103px;
//   }

//   .pageHeader:not(.sticky) .menuMainAlt {
//     bottom: 29px;
//   }
// }

.pageHeader.sticky .headerContent {
  margin-bottom: .75rem;
}

// MCTA
a.cta-button.donate {
  color: #fff;
  padding-left: 60px;
}

a.cta-button.donate:before {
  content: '';
  background-image: url("../assets/smile.svg");
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 38px;
  height: 13px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

// CTA2 - Added this class
a.cta-button.cta2 {
  padding-left: 45px !important;
}

a.cta-button.cta2:before {
  content: '';
  background-image: url("../assets/arrow_right.svg");
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 21px;
  height: 22px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

// Carousel
.carouselSlideDetail {
  position: relative;

  @media (max-width: $carousel-breakpoint) {
    padding-top: 2rem;
  }
}

.carouselSlideDetail:before {
  content: url("../assets/arrows_carousel.svg");
  position: absolute;
  top: -20px;
  left: 50px;

  @media (max-width: $carousel-breakpoint) {
    left: 1rem;
  }
}

// Home Intro
.homeIntro a.button {
  font-size: 1.2rem;
  padding: 0.7em 1.5em; // JK - Why not adjust $btn-padding-x/y?
  background-color: $brand-five;

  &:hover {
    @include button-hover-state($brand-five);
  }
}

// Map snippet

// JK - Some of this is a bit sketchy,
// Wonder if this could just be handled with a normal card?

.homeBox4 {
  // display: block !important; // JK - Why?
  background-color: $brand-four;
  color: #fff;
  padding: 12rem 0;
  text-align: center;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto; // Doesn't cover the feature at lower resolutions
    opacity: 0.3;
  }

  div {
    position: relative;
    width: 400px; // Causes issues
    left: 50%;
    margin-left: -200px; // Causes issues

  }

  .cta-button {
    margin-top: 20px;
  }

  h2 {
    color: #fff;
  }
}

@media (max-width: 1024px) {
  .homeBox4 {
    padding: 8rem 0;
  }
}

@media (max-width: 600px) {
  .homeBox4 {
    max-height: 250px;
    padding: 4rem 0;
  }
}
@media (max-width: 400px) {
  .homeBox4 {
    max-height: 200px;
    div {
      margin-left: -50%;
    }
  }
}

// Feeds

.homeFeedBox1 .feedList {
  position: relative;
}

.homeFeedBox1 .feedList:after {
  content: "";
  display: block;
  position: absolute;
  background-image: url("../assets/double_circle.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  bottom: -80px;
  right: 0;
  width: 200px;
  height: 100px;
  z-index: 1;
}

@media (max-width: 600px) {
  .homeFeedBox1 .feedList:after {
    width: 180px;
    height: 80px;
  }
}


//Stats
.homeImpactWrapper table h2 {
  padding-top: 60px;
}

.homeImpactWrapper td:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.homeImpactWrapper tr:first-of-type td:first-child:before {
  width: 100px;
  height: 60px;
  background-image: url("../assets/stats_smile.svg");
}

.homeImpactWrapper tr:nth-of-type(2) td:first-child:before {
  width: 100px;
  height: 60px;
  background-image: url("../assets/stats_arrows.svg");
}

.homeImpactWrapper tr:nth-of-type(3) td:first-child:before {
  width: 120px;
  height: 90px;
  background-image: url("../assets/stats_double_circle.svg");
  top: -10px;
}

// Hide nav on donate as per client request
.donate {
  .headerContent {
    min-height: 60px !important;
  }
  .pageHeader.sticky .headerContent {
    margin-bottom: 0.3rem;
  }
  .menuMain,
  .socialIcons,
  .mainCallToActionButtons,
  .header-search,
  .menuAdminContainer,
  .menuMainAlt {
    display: none;
  }
}


//
//	Tab-style donations - Be good to roll this in
//
.donationFrequency.frequencySelected {
  background-color: transparent !important;
}

.donationFrequency {
  flex-direction: row !important;
  padding: 0 !important;
  border: 0px !important;
  border-bottom: 1px solid #ececec !important;
  margin-top: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  background-color: transparent !important;
}

#Single, #Regular {
  display: inline-block;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px 5px 0 0;
  color: #333;
}

#Single.active,
#Regular.active {
  background-color: $brand-secondary !important;
  color: #fff;
}

.formQuestion.donationDate,
.formQuestion.donationPeriod {
  background-color: #fff;
}

// Sidebar
.associated:not(.associatedBox) li {
  border-color: darken($grey-lightest, 10%);
}

// Newsletter
// .footerBox .charityEmailForm {
//   display: flex;
//   align-items: flex-end;
//   flex-wrap: wrap;
//   width: 100%;
//   max-width: $container-max-width;
//   margin: 0 auto;
//   padding: 0 $site-bleed;

//   h2 {
//     width: 100%;
//     text-align: center;
//     margin-bottom: 30px;
//   }

//   .formQuestion {
//     flex: 1;
//     padding-right: 1.5rem;
//     padding-bottom: 0;
//   }

//   input[type="Submit"] {
//     display: inline-flex;
//     background-color: $brand-four;
//     border-radius: $btn-border-radius;
//     color: #fff;
//     height: 38px; // Match inputs
//     padding: 0 1.5rem;

//     &:hover {
//       cursor: pointer;

//       @include button-hover-state($brand-four);
//     }
//   }

//   // Terms
//   > span {
//     width: 100%;
//     margin-top: 30px;
//     text-align: center;
//   }

//   @include max-width(sm) {
//     .formQuestion {
//       flex: auto;
//       padding-right: 0;
//       width:  100%;
//       padding-bottom: 15px;
//     }

//     input[type="Submit"] {
//       margin: 0 auto;
//     }
//   }
// }

// Newsletter
#mc_embed_signup {
  width: 100%;
  max-width: $container-max-width;
  margin: 0 auto;
  padding: 0 $site-bleed;
}

.indicates-required {
  width: 100%;
  font-size: $font-size-small;
  text-align: center;
  margin-bottom: 10px;
}

#mc_embed_signup_scroll {
  display: flex;
  flex-wrap: wrap;

  > .mc-field-group {
    width: calc(100% / 3);
    display: flex;
    flex-direction: column;
    padding-right: $gap-width;

    // Last name
    &:nth-child(4) {
      padding-right: 0;
    }
  }

  label {
    font-size: $font-size-small;
  }

  .checkbox.subfield {
    font-size: 1rem;
    display: flex;
    align-items: center;

    input {
      margin-right: .5em;
    }
  }

  .mergeRow {
    width: 100%;
    margin-top: $gap-width;
  }

  .clear {
    width: 100%;
    text-align: center;
  }

  @media (max-width: map-get($breakpoints, sm)) {
    > .mc-field-group {
      width: 100%;
      padding-right: 0;
      padding-bottom: 20px;
    }
  }
}

#mc-embedded-subscribe {
  margin: 10px auto 0;
  background-color: $brand-four;
  color: #fff;
  &:hover {
    cursor: pointer;
    @include button-hover-state($brand-four);
  }
}

// Subsites
.menuSub {
  padding: 10px 0;
  border-radius: $border-radius;
  ul li {
    margin: 0 20px;
  }
}



// Add border-left on blockquotes
blockquote {
  border-left: 5px solid $brand-primary;
}


// Footer

.footerBox2, .footerBox3 {
  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 33.3333%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
}

.footerBox4 {
  text-align: center;

  ul.socialIcons {
    margin: 20px auto;
    justify-content: center;
  }

  p {
    font-size: $font-size-small;
  }

  p:last-child {
    margin: 20px 0 0;
  }

  img {
    margin-right: 20px;
  }

}


// Hide some furniture based on category
// Hide sidebar
.PostCategory_hide-sidebar .postAside {
	display: none;
}
.PostCategory_hide-sidebar .postContent {
	max-width: 900px;
}

// Hide wider furniture (buttons, social etc)
.PostCategory_hide-screen-extras {
  .headerWrapper {
    margin-top: 8rem;
  }
  .pageHeader.sticky .headerContent {
    margin-bottom: 2.3rem;
  }
  .menuMain,
  .socialIcons,
  .mainCallToActionButtons,
  .header-search,
  .menuAdminContainer,
  .menuMainAlt {
    display: none;
  }
}

// DES-4797
@media (max-width: 576px) {
  .footerBox2 {
    grid-column: 3 / span 6;
  }
  .footerBox.footerBox3 {
    grid-column: 9 / span 6;
    grid-row: 2 / 4;
  }
}

@media (max-width: 768px) {
  body {
    font-size: 1rem;
  }
}