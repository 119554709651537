$platform-name: 'homestart2019';
// $assets-path: '/fresco/clients/#{$platform-name}/assets/'; // JK - Shouldn't need to touch this


$brand-primary: #500778;
$brand-secondary: #E35205;
$brand-tertiary: #FFA300;
$brand-four: #00B0B9;
$brand-five: #DB0A5B;

$grey-lighter: #eceeef;

// layout
$gap-width: 20px;
$site-bleed: 20px;
$border-radius: 2px;

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;


// Logo
$logo: 'logo.svg';
$logo-width: 170px;
$logo-height: 107px;
$logo-absolute: true;

// Typography
$font-family-base: 'Source Sans Pro', sans-serif !default;
$headings-colour: $brand-secondary;
// $headings-line-height: 2;

// Buttons
$btn-padding-x: 2em;
$btn-padding-y: 1em;
$btn-hover-style: lighten;
$donate-btn-colour: #fff;

// Social
$social-icons-background-colour: transparent;
$social-icons-colour: $brand-primary;

// Menu admin - Can't add margin to this?
// $menu-admin-enabled: false;
$menu-admin-background-colour: transparent;
$menu-admin-absolute: true;
$menu-admin-login-link-enabled: false;
$menu-admin-manager-link-enabled: false;
$menu-admin-my-details-link-enabled: false;
$menu-admin-logout-link-enabled: false;

// Header
$cta-btn-background-colour: $brand-tertiary;
$header-content-margin-bottom: 0;
$cta-padding-right-at-nav-breakpoint: 115px;

// Search - Be good to have a variable for just the icon?
// JK - just put it in socialIcons?
$header-search-input-max-width: 0;
$header-search-input-margin-right: 0;
$header-search-input-padding: 0 0;
$header-search-input-border-colour: #fff;
$header-search-button-background-colour: transparent;
$header-search-button-icon-colour: $brand-tertiary;

// Sticky header
$header-sticky-enabled: true;
$header-sticky-background-colour: #fff;
$header-sticky-cta-btns-enabled: false;
$header-sticky-header-content-margin-bottom: 0;

// Tagline
// Trying to see if I could get the tagline to be useful
// as a replacement for admin elements jumped to footer.
// Would want it to align right next to social icons etc
// $tagline-enabled: false;

// Navigation
$nav-background-colour: transparent;
$nav-normal-align-items: right;
$nav-normal-margin-bottom: 0;
$nav-top-level-chevrons-enabled: true;
$nav-top-level-item-hover-colour: $brand-secondary;
$nav-top-level-item-hover-background-colour: transparent;
$nav-top-level-item-font-size: 1.1em;

// Submenus
$nav-submenu-background-colour: $brand-secondary;
$nav-submenu-item-colour: #fff;


// Carousel - Could use some control over appeal buttons
$carousel-max-width: 100%;
$carousel-details-padding: $spacer * 3;
$carousel-details-background-colour: $brand-secondary;
$carousel-details-max-width: 500px;
$carousel-heading-colour: #fff;
$carousel-summary-colour: #fff;
$carousel-details-margin-y: 3rem;
$carousel-details-margin-x: 3rem;
$carousel-controls-button-background-colour: #fff;
$carousel-controls-icon-colour: $brand-primary;
$carousel-controls-position-x: split;
$carousel-controls-position-y: center;
$carousel-controls-button-border-radius: 0;
$carousel-dots-position-x: right;


// Home intro
$home-intro-margin-top: 0;
$home-intro-padding-y: $spacer * 2;

// Impact stats
$impact-stats-figure-colour: $brand-tertiary;
$impact-stats-figure-font-size: 5em;
$impact-stats-heading-margin-bottom: 0;
$impact-stats-background-image: 'roundel_purple.svg';
$impact-stats-background-image-opacity: 1;
$impact-stats-background-image-size: 90px;
$impact-stats-background-image-repeat: repeat;

// feeds
//$home-feeds-padding-bottom: $spacer * 2;
$feed-item-event-start-date-enabled: false;
$feed-item-event-location-enabled: false;

// Footer
$footer-prefab: 2;
$footer-background-colour: $brand-secondary;
$footer-padding-y: 40px;
$footer-link-colour: #fff;
$footer-colour: #fff;
$newsletter-background-colour: #EEEEEE;

// Newsletter
$newsletter-background-colour: $brand-primary;


// Listing pages
$listing-card-min-width: 300px;

// Posts
$page-title-margin-bottom: 1em;
$page-title-font-size: 3em;
$hr-border-colour: #C9C9C9;

// Sidebar
$sidebar-background-colour: $grey-lighter;
$sidebar-padding: 1.5rem;

$associated-latest-enabled: false;

// Donations
$donation-amount-min-width: 200px;

// Blockquote
$blockquote-padding: $spacer * 4;
$blockquote-background-colour: #f0f0f0;
$blockquote-border-radius: 0;

// Blogs
$listed-blog-post-author-enabled: false;
$blog-details-enabled: false;

// In-page Quickgiving panels
$quick-giving-type: columns;
$quick-giving-donation-amount-background-colour: #fff;
$quick-giving-donation-amount-selected-background-colour: $brand-primary;

// Subsites
$subsite-enabled: true;
$subsite-nav-background-colour: #f0f0f0;
$subsite-nav-margin-top: 20px;
$subsite-nav-align-items: left;
